import React, { useCallback, useEffect, useState } from "react";

import ProductDetailsCarousel from "../components/ProductDetailsCarousel";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  AiOutlineMinus,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { currencyFormatter } from "../utils/currencyFormatter";
import { BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import ProductImageCarasol from "../components/ProductImageCarasol";
import { toast } from "react-toastify";
import ProductCard from "../components/ProductCard";
import { Parser } from "html-to-react";

// component start
const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { items: data } = useSelector((state) => state.products);

  const [singleProduct, setSingleProduct] = useState({});
  const [cartQuantity, setCartQuantity] = useState(1);

  const [imageThamb, setImageThamb] = useState([]);

  console.log(singleProduct);
  const navigate = useNavigate();

  const htmlParser = new Parser();

  useEffect(() => {
    const singleProduct = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-products/${id}`
      );
      return setSingleProduct(res.data);
    };
    singleProduct();
  }, [id]);

  useEffect(() => {
    const imageThambs = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-thumb/${singleProduct?.product?.id}}`
      );

      return setImageThamb(res.data);
    };
    imageThambs();
  }, [singleProduct?.product?.id]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);
  const handleAddToCart = () => {
    dispatch(addToSingleCart({ ...singleProduct.product, cartQuantity }));
  };
  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...singleProduct.product, cartQuantity }));
    navigate("/checkout");
  };
  return (
    <div className="container mx-auto mt-8 px-2 md:px-0">
      <div className="grid grid-cols-2 md:grid-cols-9 gap-5">
        <div className="left image col-span-2  md:col-span-9 lg:col-span-3">
          {/* <div className="h-[25rem]">
            <img
              
              alt="pic"
              className="w-full h-full object-cover"
            />
          </div> */}
          <ProductImageCarasol
            singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
            imageThamb={imageThamb}
          />
        </div>
        <div className="right flex flex-col gap-8 col-span-2  md:col-span-9  lg:col-span-6">
          <div className="font-semibold text-lg lg:text-2xl">
            {singleProduct?.product?.name}
          </div>
          <div className="flex flex-col md:flex md:flex-row gap-10 md:gap-20">
            <div className="flex flex-col gap-5">
              <div>
                <div className="flex gap-5">
                  <span className="font-semibold line-through text-[#FA5303] decoration-200">
                    ৳ {singleProduct?.product?.unit_price}
                  </span>
                  <div className="font-medium text-2xl">
                    {singleProduct?.product?.after_discount > 0 ? (
                      <>
                        ৳{" "}
                        {+singleProduct?.product?.after_discount * cartQuantity}
                      </>
                    ) : (
                      <>
                        ৳ {+singleProduct?.product?.unit_price * cartQuantity}
                      </>
                    )}
                  </div>
                </div>
                <div className="">
                  {singleProduct?.product?.discount > 0 ? (
                    <>
                      <div className="text-[#64be1f] text-[1.4rem] flex gap-2">
                        <span>Discount:</span>
                        {Math.round(
                          ((singleProduct?.product?.unit_price -
                            singleProduct?.product?.after_discount) *
                            100) /
                            singleProduct?.product?.unit_price
                        )}
                        %
                      </div>
                    </>
                  ) : (
                    <p className="text-[#16995D] text-2xl">Discount: 0 %</p>
                  )}
                </div>
                <div className="stoke-status mt-1 text-[#16995D]">
                  {singleProduct?.product?.current_stock > 0 && (
                    <span className="text-[1.4rem]">In stock</span>
                  )}
                </div>
                <div className="stoke-status mt-1 text-red-900">
                  {singleProduct?.product?.current_stock <= 0 && (
                    <span className="text-[2rem]">Out of Stock</span>
                  )}
                </div>
              </div>

              <div className="flex gap-3">
                <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
                  Quantity :
                </span>
                <div className="flex items-center gap-4 ">
                  <button
                    className=" bg-slate-500/30 rounded-full flex justify-center items-center font-semibold text-xs h-5 w-5 hover:bg-[#0ea5e9] hover:text-white duration-500"
                    onClick={() => handleDecrease(singleProduct?.product)}
                  >
                    <AiOutlineMinus />
                  </button>
                  <span>{cartQuantity}</span>
                  <button
                    className=" bg-slate-500/30 h-5 w-5  rounded-full flex justify-center items-center hover:bg-[#0ea5e9] hover:text-white duration-500"
                    onClick={() => handleIncrease(singleProduct?.product)}
                  >
                    <BsPlus />
                  </button>
                </div>
              </div>
            </div>
            {/* video  */}
            {singleProduct?.product?.video_link ? (
              <div className="overflow-hidden">
                <iframe
                  width="300"
                  height="215"
                  src={singleProduct?.product?.video_link}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-2 text-white">
            <button
              onClick={handleBuyNow}
              className="w-full py-3 px-5 bg-[#F7681A]  flex items-center justify-center rounded-md text-lg"
            >
              অর্ডার করুন।
            </button>

            <button
              onClick={handleAddToCart}
              className="w-full bg-[#16995D] py-3 px-5 flex items-center justify-center rounded-md text-lg"
            >
              Add to Cart
            </button>

            <a
              href="tel://+8801814-772233"
              className="w-full py-2 px-5 bg-blue-500 hover:bg-[#ED423E] rounded-md duration-300 col-span-2 flex flex-col items-center justify-center"
            >
              <span className="">কল করুন</span>
              <span className="text-lg">01814-772233</span>
            </a>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="text-[#13b86d]">
          <SectionTitle title={"Discription"} />
        </div>

        <div className="">
          <span className="">
            {htmlParser.parse(singleProduct?.product?.description)}
          </span>
        </div>
      </div>
      <div className="mb-20">
        <RelatedProduct singleProduct={singleProduct} />
      </div>

      <div className="mt-10">
        <ProductCard data={data} />
      </div>
    </div>
  );
};

export default ProductDetails;
