import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, HashNavigation, Navigation } from "swiper";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { useState } from "react";
import SubCat from "../components/SubCat";

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.slider);

  const { items: subcategories } = useSelector((state) => state.subCategor);
  console.log(subcategories);

  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
  const [menuLeaveTimeout, setMenuLeaveTimeout] = useState(null);
  const [expandedMenuIndex, setExpandedMenuIndex] = useState(-1);
  // const [showCategoryMenu, setshowCategoryMenu] = useState(false);

  const handleMenuHover = (index) => {
    setActiveMenuIndex(index);
    clearTimeout(menuLeaveTimeout);
  };

  const handleMenuLeave = () => {
    const timeout = setTimeout(() => {
      setActiveMenuIndex(-1);
    }, 200);
    setMenuLeaveTimeout(timeout);
  };

  const getSubcategoriesByCategoryId = (categoryId) => {
    return subcategories.filter(
      (subcategory) => +subcategory.category_id === categoryId
    );
  };
  const getMenuIcon = (index) => {
    if (activeMenuIndex === index || expandedMenuIndex === index) {
      return <MdOutlineKeyboardArrowUp />;
    }
    return <MdOutlineKeyboardArrowDown />;
  };

  return (
    <div className="container mx-auto  mt-5 ">
      <div className="grid md:grid-cols-4 grid-cols-1 gap-3">
        <div className="hidden md:block relative">
          <SubCat
            handleMenuHover={handleMenuHover}
            getMenuIcon={getMenuIcon}
            getSubcategoriesByCategoryId={getSubcategoriesByCategoryId}
            handleMenuLeave={handleMenuLeave}
            activeMenuIndex={activeMenuIndex}
            expandedMenuIndex={expandedMenuIndex}
            // setshowCategoryMenu={setshowCategoryMenu}
          />
        </div>
        <div className="banner-slider-frame col-span-3 ">
          <div className="banner-slider">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              speed={700}
              hashNavigation
              loop={true}
              modules={[Autoplay, Navigation, HashNavigation]}
              className="mySwiper h-full"
            >
              {data.map((image) => (
                <div>
                  <SwiperSlide>
                    <div className="w-full  md:h-[21rem] h-[12rem] 2xl:h-[30rem] overflow-hidden">
                      <img
                        key={image.id}
                        src={`${process.env.REACT_APP_URL}/public/uploads/slider/${image?.slider}`}
                        alt={image.alt}
                        className={` h-full  w-full  transition-opacity duration-500 object-cover `}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
        {/* <div>
          <div className="h-full hidden md:block">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              speed={700}
              loop={true}
              hashNavigation
              modules={[Autoplay, Navigation, HashNavigation]}
              className="mySwiper h-full"
            >
              {imgData1.map((image) => (
                <div key={image.data}>
                  <SwiperSlide>
                    <div className="w-full  h-[21rem]  2xl:h-[30rem] overflow-hidden">
                      <img
                        key={image.id}
                        src={image.img}
                        alt={image.alt}
                        className={` h-full w-full  transition-opacity duration-500 object-cover 2xl:object-fill`}
                      />
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BannerSlider;
