import { configureStore } from "@reduxjs/toolkit";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";
import footerDetailsReducer, {
  footerDetails,
} from "../features/products/footerAboutSlice";

import sliderBannerReducer, {
  sliderBannerFatching,
} from "../features/banner/sliderBannerSlice";

import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/homeBannerSlice";

import logoReducer, { logoFatching } from "../features/logo/logoSlice";

import subCategoryReducer, {
  subCategoryFetching,
} from "../features/categories/subCategories";

export const store = configureStore({
  reducer: {
    //reducer in here
    products: productReducer,
    cart: cartReducer,
    category: categoryReducer,
    bestSell: bestSellingReducer,
    footerDetails: footerDetailsReducer,
    slider: sliderBannerReducer,
    banner: homeBannerReducer,
    logo: logoReducer,
    subCategor: subCategoryReducer,
  },
});

store.dispatch(productFetching());
store.dispatch(categoryFetching());

store.dispatch(bestSellingFetching());
store.dispatch(footerDetails());

store.dispatch(sliderBannerFatching());
store.dispatch(homeBannerFetching());
store.dispatch(logoFatching());

store.dispatch(subCategoryFetching());
