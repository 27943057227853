import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addtoCart,
  decreaseCart,
  getSubtotal,
  removeAllFromCart,
  removeFromCart,
  // removeFromCart,
} from "../features/products/cartSlice";

import { currencyFormatter } from "../utils/currencyFormatter";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";

const Checkout = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const [orderItem, setOrderItem] = useState(data);
  console.log(orderItem);
  const [response, setResponse] = useState(0);

  // console.log(JSON.stringify(orderItem).split(" "));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: 120,
    shipping_type: "Cash On Delivery",
  });
  const [couponCode, setCouponCode] = useState("");

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleDecrease = (product) => {
    if (product?.cartQuantity > 1) {
      dispatch(decreaseCart(product));
    } else {
    }
  };

  const handleIncrease = (product) => {
    dispatch(addtoCart(product));
  };

  const handleRemove = (product) => {
    dispatch(removeFromCart(product));
  };
  const applyCoupon = async () => {
    setCouponCode("");
    try {
      await fetch(`${process.env.REACT_APP_URL}/api-coupon-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          coupon: couponCode,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setResponse(data);
        });
    } catch (err) {
      console.log(err.message);
    }
  };
  let grand_total = response.discount
    ? cartTotalAmount + +formData.shipping_cost + -response.discount
    : cartTotalAmount + +formData.shipping_cost + 0;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: orderItem,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            grand_total,
          }),
        });

        if (!res.ok) throw new Error("Something Went Error");
        if (res.ok) {
          dispatch(removeAllFromCart());
          navigate("/login");
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        item: JSON.stringify(orderItem),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });

      window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    }
  };

  // function setCustomValidity(textbox) {
  //   if () {
  //     textbox.setCustomValidity("must give 11 word");
  //   } else {
  //     textbox.setCustomValidity("");
  //   }
  //   return true;
  // }

  // const numberText = document.getElementById("number");

  // if (numberText) {
  //   const numberTow = parseInt(numberText);

  //   alert(numberTow);
  // }
  // if (document.getElementById("number").leanth) {
  //   //Male radio button is checked
  // } else if (document.getElementById("gender_Female").checked) {
  //   //Female radio button is checked
  // }

  return (
    <div className="main-wrapper">
      <div className="container mx-auto mt-20 px-2">
        <div className="grid md:grid-cols-9 gap-10">
          <div className="product-details md:col-span-5 lg:col-span-6">
            <div>
              <div className="product-headlines grid grid-cols-5 gap-10 border-b pb-3 uppercase font-medium">
                <div className="col-product col-span-2">Product</div>
                <div className="col-span-3  hidden lg:block">
                  <div className="flex justify-between">
                    <div className="">Price</div>
                    <div className="">Quantity</div>
                    <div className="">Total</div>
                  </div>
                </div>
              </div>

              {data.map((product) => (
                <div
                  key={product.id}
                  className="products grid grid-cols-5 gap-10 border-b pb-3 uppercase font-medium px-2"
                >
                  <div className="col-product col-span-5 lg:col-span-2 flex gap-3 items-center">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
                      alt="leptop"
                      className="w-28 h-28 object-cover"
                    />
                    <div className="name">
                      {product?.name?.substring(0, 60)}...
                    </div>
                  </div>
                  <div className="flex gap-4 md:gap-7 col-span-3 items-center justify-between">
                    <div className="col-unit-price">
                      {product?.after_discount > 0 ? (
                        <span className="flex gap-2">
                          <span>৳</span>
                          <span>{+product?.after_discount}</span>
                        </span>
                      ) : (
                        <span className="flex">
                          <span>৳</span>
                          <span>{+product?.unit_price}</span>
                        </span>
                      )}
                    </div>
                    <div className="">
                      <div className="counter flex">
                        <button
                          onClick={() => handleDecrease(product)}
                          className="h-8 cursor-pointer w-8 md:h-10 md:w-10 bg-gray-100  border border-gray-300 active:bg-gray-700 active:text-gray-50"
                        >
                          -
                        </button>
                        <span className="h-8 w-8 md:h-10 md:w-10 bg-gray-100 flex justify-center items-center border border-gray-300">
                          {product?.cartQuantity}
                        </span>
                        <button
                          onClick={() => handleIncrease(product)}
                          className="h-8 w-8 md:h-10 md:w-10 bg-gray-100 border border-gray-300 active:bg-gray-700 active:text-gray-50"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="">
                      {product.after_discount > 0 ? (
                        <div className="flex gap-2">
                          <span>৳</span>
                          <span>
                            {product?.after_discount * product?.cartQuantity}
                          </span>
                        </div>
                      ) : (
                        <div className="flex gap-2">
                          <span>৳</span>
                          <span>
                            {product?.unit_price * product?.cartQuantity}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <div className="delete hover:text-rose-500 duration-300">
                    <RiDeleteBin6Line onClick={() => handleRemove(product)} />
                  </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="customer-details md:col-span-4 lg:col-span-3 border">
            <form onSubmit={handleFormSubmit} className="flex flex-col gap-10">
              <div className="border-4 border-green-500">
                <div className="flex items-center justify-center">
                  <div className="text-red-500 p-10">
                    অর্ডার কনফার্ম করতে আপনার নাম, মোবাইল নাম্বর,ঠিকানা লিখে "
                    অর্ডার করুন " বাটনে ক্লিক করুন।
                  </div>
                </div>

                <div className="flex flex-col gap-2 px-10">
                  <div className="form-control flex flex-col mb-3">
                    <label className="mb-2">নাম</label>
                    <input
                      required
                      type="text"
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      value={formData.name}
                      className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                      placeholder="Enter Your First Name"
                    />
                  </div>
                  <div className="form-control flex flex-col   mb-3">
                    <label className="mb-2">মোবাইল নাম্বার</label>
                    <input
                      required
                      type="tel"
                      pattern="[0-9]{11}"
                      id="number"
                      onChange={(e) =>
                        setFormData({ ...formData, phone: e.target.value })
                      }
                      // onChange={handleChange}
                      value={formData.phone}
                      className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
                      placeholder="Enter Your Number"
                    />
                    <mat-error></mat-error>
                  </div>
                  <div className="form-control flex flex-col mb-3">
                    <label className="mb-2">ঠিকানা</label>
                    <textarea
                      required
                      type="text"
                      onChange={(e) =>
                        setFormData({ ...formData, address: e.target.value })
                      }
                      value={formData.address}
                      className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                      placeholder="Enter Your Address"
                    />
                  </div>

                  <label>Shipping Method</label>
                  <select
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        shipping_cost: e.target.value,
                      })
                    }
                    className="px-3 py-3 mb-4  rounded-md outline-none border focus:border-[#16995D] duration-300"
                  >
                    {/* <option value="0"></option> */}
                    <option value="120">ঢাকার বাহিরে 120 টাকা</option>
                    <option value="60">ঢাকার ভিতরে 60 টাকা</option>
                    {/* <option value="100">Dhaka Subcity(100)</option> */}
                  </select>
                </div>
              </div>

              <div className="flex justify-center  items-center gap-2 text-lg">
                {/* <div className="flex gap-2">
                <input
                  type="radio"
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shipping_cost: e.target.value,
                    })
                  }
                  name="shipping_cost"
                  id="in_dhaka"
                  value={60}
                  className="text-[#16995D]"
                />
                <label className="text-red-500 text-sm md:text-lg">
                  ঢাকার ভিতরে 60 টাকা
                </label>
              </div>

              <div className="flex gap-2">
                <input
                  type="radio"
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shipping_cost: e.target.value,
                    })
                  }
                  id="outside_dhaka"
                  name="shipping_cost"
                  value={120}
                  className=""
                />
                <label className="text-red-500 text-sm md:text-lg">
                  ঢাকার বাহিরে 120 টাকা
                </label>
              </div> */}
              </div>

              <div className="flex items-center, justify-center">
                <button
                  type="submit"
                  className="bg-[#16995D] text-white hover:bg-green-600 px-6 py-4 rounded-md duration-300"
                >
                  অর্ডার কনফার্ম করুন।
                </button>
              </div>

              <div className="border-4 border-green-500 flex flex-col gap-5 px-10 pb-10">
                <div className="flex items-center justify-center mt-5">
                  <p className="font-semibold text-xl text-[#16995D]">
                    Order-summary
                  </p>
                </div>

                <div className="flex flex-col gap-3">
                  <div className="flex justify-between">
                    <span className="text-[#16995D] text-2xl">Subtotal:</span>
                    <span> ৳ {cartTotalAmount}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#16995D] text-2xl">
                      Delivery Charge:
                    </span>
                    <span> ৳ {+formData.shipping_cost}</span>
                  </div>
                </div>
                <div className="bg-green-500 h-[0.10rem]"></div>
                <div>
                  <div className="flex justify-between mb-10">
                    <span className="text-[#16995D] text-2xl capitalize">
                      total
                    </span>
                    <span> ৳ {grand_total}</span>
                  </div>
                </div>

                <div className="form-control">
                  <div className="flex justify-center">
                    {/* <button
                    type="submit"
                    className="bg-[#16995D] text-white hover:bg-green-600 px-6 py-4 rounded-md duration-300"
                  >
                    অর্ডার করুন।
                  </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
