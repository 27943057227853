import { useSelector } from "react-redux";
import Card from "../components/Card";
import SectionTitle from "../components/SectionTitle";

const FeaturedProduct = ({ visible, handleSeeMore, handleless }) => {
  const { items: data } = useSelector((state) => state.products);
  return (
    <div className="wrapper my-10">
      {/* <SectionTitle title={"Featured Products"} /> */}

      {/* {data?.length > 0 &&
        data?.map((product) => <Card key={product.id} product={product} />)} */}

      <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2 md:gap-5">
        {data?.length > 0 &&
          data
            .slice(0, visible)
            .map((product) => <Card key={product.id} product={product} />)}
      </div>

      <div className="flex items-center justify-center my-8">
        {data.length > visible ? (
          <button
            className="bg-[#16995D] text-white hover:bg-green-600 px-5 py-3 rounded-md duration-300"
            onClick={handleSeeMore}
          >
            Load More
          </button>
        ) : null}
        {data?.length === visible && (
          <button
            className="bg-[#16995D] text-white hover:bg-green-600 px-5 py-3 rounded-md duration-300"
            onClick={handleless}
          >
            Load less
          </button>
        )}
      </div>
    </div>
  );
};

export default FeaturedProduct;
