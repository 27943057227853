import { useSelector } from "react-redux";
import BannerImage from "./BannerImage";

const Banner = () => {
  const { items: banners } = useSelector((state) => state.banner);
  console.log(banners);

  return (
    <div className="container mx-auto my-10">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-3 md:gap-5">
        {banners?.map((data) => (
          <BannerImage key={data.id} data={data} />
        ))}
      </div>
    </div>
  );
};

export default Banner;
