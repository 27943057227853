import { currencyFormatter } from "../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { BsCartCheck } from "react-icons/bs";
const Card = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addToCartHandler = (product) => {
    dispatch(addtoCart(product));
    navigate("/checkout");
  };

  const [cartQuantity, setCartQuantity] = useState(1);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, cartQuantity }));
    navigate("/checkout");
  };
  return (
    <div className=" group  duration-300">
      <div className="flex flex-col gap-5 items-center justify-center p-3 bg-white border-2 group-hover:border-[#FD22D7] duration-300">
        <Link
          to={`/productdetails/${product?.id}`}
          className="flex flex-col gap-3"
        >
          <div className="h-[10rem] md:h-[12rem] overflow-hidden">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>

          <div className="product-details h-[7rem] flex flex-col gap-2 justify-center items-center">
            <div className="font-semibold hover:text-[#FD22D7] duration-300 h-[3rem]">
              {product?.name?.substring(0, 28)}..
            </div>
            <div className="flex gap-2">
              <div className="flex lg:text-[16px] text-[12px] gap-2">
                {product?.discount > 0 ? (
                  <>
                    <span className="font-semibold line-through text-[#FD22D7] decoration-200">
                      ৳ {product?.unit_price}
                    </span>
                    <span className="font-semibold text-[#FD22D7] text-xl">
                      ৳ {product?.after_discount}
                    </span>
                  </>
                ) : (
                  <span className="font-semibold text-[#FD22D7] text-xl">
                    ৳ {product?.unit_price}
                  </span>
                )}
              </div>
            </div>
            <div className="">
              {product?.discount > 0 ? (
                <>
                  <div className="text-[#FD22D7] text-base md:text-xl flex gap-2">
                    <span>Discount:</span>
                    {Math.round(
                      ((product?.unit_price - product?.after_discount) * 100) /
                        product?.unit_price
                    )}
                    %
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Link>

        <div className="w-full  flex items-center justify-center rounded-md bg-[#FD22D7] group-hover:bg-[#464758] duration-300">
          <button
            onClick={handleBuyNow}
            className="py-2 px-5 flex justify-center items-center gap-1  md:gap-2 text-white  md:text-base font-normal md:font-medium"
          >
            <span className="flex justify-center items-center">
              <BsCartCheck />
            </span>
            <span className="text-[14px] md:text-base flex justify-center items-center">
              অর্ডার করুন
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
