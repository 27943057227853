import { useEffect, useState } from "react";
import Banner from "../components/Banner";
import BannerToow from "../components/BannerToow";
import CategoryCard from "../components/CategoryCard";
import ProductCard from "../components/ProductCard";

import BannerSlider from "./BannerSlider";
import BestSelling from "./BestSelling";
import FeaturedProduct from "./FeaturedProduct";

// import ReviewCardPage from "./ReviewCardPage";

const Home = () => {
  const [visible, setVisible] = useState(30);

  const handleSeeMore = () => {
    setVisible((prevValue) => prevValue + 12);
  };

  const handleless = () => {
    setVisible((prevValue) => prevValue - 6);
  };

  // const [categoryProduct, setCategoryProduct] = useState([]);
  // console.log(categoryProduct);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const res = await fetch(`${process.env.REACT_APP_MEHENDI_REVIEW_API}`);
  //       const data = await res.json();
  //       setCategoryProduct(data);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   };
  //   fetchData();
  // }, []);
  return (
    <div className="container mx-auto py-4 2xl:px-0 xl:px-14  ">
      <BannerSlider />
      <CategoryCard />

      <FeaturedProduct
        visible={visible}
        handleSeeMore={handleSeeMore}
        handleless={handleless}
      />
      <Banner />
    </div>
  );
};

export default Home;
